var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('h3',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.certification", false, "Certificación de Credenciales" ))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'mesh.certification',"allows_crud":_vm.allows_crud}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('ButtonMedium',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_certification'),expression:"'mesh.add_certification'"}],attrs:{"tooltip_text":`Agregar ${_vm.$getVisibleNames(
            'mesh.certification',
            false,
            'Certificación de Credenciales'
          )}`,"text_button":`Agregar`,"click_button":() => _vm.$bvModal.show(`new-certification-modal`),"icon":'plus',"variant":'primary'}}):_vm._e()],1)]),_c('GenericBTable',{attrs:{"items":_vm.certifications,"fields":_vm.certificationsFields,"filter":_vm.input_search,"filterCustom":_vm.filterCustom,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"display_id":_vm.display_id,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"emitChangeSearchField":_vm.changeInputSearch,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(matters)",fn:function(row){return [_c('div',{staticClass:"campus-container"},_vm._l((_vm.getMatterName(row.item.matters)),function(item){return _c('div',{key:item.id,staticClass:"campus-div"},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]}},{key:"cell(career)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.getCareer(row.item.career))+" ")]}},(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('ButtonSmall',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_certification'),expression:"'mesh.change_certification'"}],class:``,attrs:{"tooltip_text":`Editar ${_vm.$getVisibleNames(
            'mesh.certification',
            false,
            'Certificación de Credenciales'
          )}`,"click_button":() => _vm.$bvModal.show(`edit-certification-modal-${row.item.id}`),"icon":'square'}}),_c('ButtonSmall',{directives:[{name:"can",rawName:"v-can",value:('mesh.delete_certification'),expression:"'mesh.delete_certification'"}],attrs:{"click_button":() => _vm.askForDeleteCertification(row.item.id),"tooltip_text":`Eliminar ${_vm.$getVisibleNames(
            'mesh.certification',
            false,
            'Certificación de Credenciales'
          )}`,"icon":'trash'}}),_c('b-modal',{attrs:{"id":`edit-certification-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'mesh.certification',
            false,
            'Certificación de Credenciales'
          )}`,"size":"lg","hide-footer":""}},[_c('CertificationForm',{attrs:{"Certification":row.item,"show_title":false},on:{"updated":_vm.slotUpdatedCertification}})],1)]}}:null],null,true)})],1),_c('b-modal',{attrs:{"id":`new-certification-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'mesh.certification',
      false,
      'Certificación de Credenciales'
    )}`,"size":"lg"}},[_c('CertificationForm',{on:{"created":_vm.slotCreatedCertification}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }